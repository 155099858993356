<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="card card-custom gutter-b example example-compact"
          v-if="filter"
        >
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-lg-10 col-xl-8">
                <div class="row align-items-center">
                  <div class="col-md-2 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="id"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Id"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-3 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="name"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="Mijoz nomi"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-3 my-2 my-md-0">
                    <div class="input-icon">
                      <input
                        type="text"
                        v-model="inn"
                        v-debounce:400ms="myFn"
                        class="form-control"
                        placeholder="inn"
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-2 my-2 my-md-0">
                    <div class="d-flex align-items-center">
                      <!-- <label class="mr-3 mb-0 d-none d-md-block">Status:</label> -->
                      <select
                        v-model="type"
                        @change="myFn"
                        class="form-control"
                        id="kt_datatable_search_status"
                      >
                        <option value="" selected disabled>Shakli</option>
                        <option value="NAQD">Kelishuv</option>
                        <option value="CONT">Shartnoma</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 my-2 my-md-0">
                    <div class="d-flex align-items-center">
                      <!-- <label class="mr-3 mb-0 d-none d-md-block">Type:</label> -->
                      <select
                        @change="myFn"
                        v-model="conttype"
                        class="form-control"
                        id="kt_datatable_search_type"
                      >
                        <option value="" selected disabled>Turi</option>
                        <option value="MU">Muddatli</option>
                        <option value="MS">Muddati cheklanmagan</option>
                        <option value="SU">Summaga bog'liq</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-xl-4 mt-5 mt-lg-0 text-right">
                <v-btn small @click="reset" color="error">{{
                  $t('BREADCRUMBS.CLEAN')
                }}</v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.MAIN_ASSETS.TRANSPORT_DOC.CERTIFICATE') }}
              </h3>
            </div>

            <div class="card-button">
              <v-btn color="warning mx-2" @click="filter = !filter">
                {{ $t('BREADCRUMBS.SEARCH') }}

                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn to="/driverpermitscreate" class="ml-2" color="primary"
                >{{ $t('MENU.MAIN_ASSETS.TRANSPORT_DOC.CERTIFICATE') }} +</v-btn
              >
            </div>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import ContractsAction from "@/view/content/dropdown/ContractsAction.vue";

export default {
  data() {
    return {
      filter: false,
      conttype: '',
      type: '',
      inn: '',
      name: '',
      id: '',
      payTypes: [
        { name: 'Muddatli', value: 'MU' },
        { name: 'Muddati cheklanmagan', value: 'MS' },
        { name: "Summaga bog'liq", value: 'SU' }
      ],
      types: [
        { name: 'Kelishuv', value: 'NAQD' },
        { name: 'Shartnoma', value: 'CONT' }
      ]
    }
  },
  props: {
    items: Object
  },
  created() {
    if (this.$store.state.requests.filterData.data !== undefined) {
      const data = this.$store.state.requests.filterData.data
      if (
        this.$store.state.requests.filterData.path.substring(
          0,
          this.$route.path.lastIndexOf('/')
        ) == this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))
      ) {
        this.filter = true
        this.conttype = data.contract_type
        this.type = data.payment_type
        this.inn = data.client__inn__icontains
        this.name = data.client__full_name__icontains
        this.id = data.id
      } else {
        this.filter = false
        this.$store.commit('setFilterData', {
          data: undefined,
          path: ''
        })
      }
    }
  },
  methods: {
    reset() {
      this.id = ''
      this.name = ''
      this.inn = ''
      this.type = ''
      this.conttype = ''
      this.$store.commit('setFilterData', {
        data: undefined,
        path: ''
      })
      this.myFn()
    },
    myFn() {
      const data = {}
      if (this.id !== '') {
        data.id = this.id
      }
      if (this.name !== '') {
        data.client__full_name__icontains = this.name
      }
      if (this.inn !== '') {
        data.client__inn__icontains = this.inn
      }
      if (this.conttype !== '') {
        data.contract_type = this.conttype
      }
      if (this.type !== '') {
        data.payment_type = this.type
      }
      this.$store.commit('setFilterData', {
        data: data,
        path: this.$route.path
      })
      this.$store.dispatch('contractsSearch', data)
      if (this.$route.path !== '/allcontracts/1') {
        this.$router.push('/allcontracts/' + '1')
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.MAIN_ASSETS.TITLE') },
      { title: this.$t('MENU.MAIN_ASSETS.TRANSPORT.TITLE') },
      {
        title: this.$t('MENU.MAIN_ASSETS.TRANSPORT_DOC.CERTIFICATE')
      }
    ])
  }
}
</script>
<style>
@media (min-width: 992px) {
  .content {
    padding: 0 !important;
  }
}
</style>
